@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

* {
	scroll-behavior: smooth;
	@apply transition-colors duration-300;
	font-family: "Lexend";
	letter-spacing: -0.5px;
}

* {
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* For Webkit-based browsers (Chrome, Safari, Edge) */
*::-webkit-scrollbar {
	display: none;
}

body {
	transition: background-color 0.5s, color 0.5s;
	overscroll-behavior: none;
}
p {
	letter-spacing: "-1px";
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Grotta" !important;
}

body.light-mode {
	background-color: #dcf2ff;
	color: #040d12;
}

body.dark-mode {
	background-color: #040d12;
	color: #dcf2ff;
}

@font-face {
	font-family: "Grotta";
	src: url(/public/fonts/Grotta-Trial-Regular.otf);
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Grotta";
	src: url(/public/fonts/Grotta-Trial-Medium.otf);
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Grotta";
	src: url(/public/fonts/Grotta-Trial-Bold.otf);
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Grotta";
	src: url(/public/fonts/Grotta-Trial-Light.otf);
	font-weight: 300;
}

@font-face {
	font-family: "Grotta";
	src: url(/public/fonts/Grotta-Trial-Semibold.otf);
	font-weight: 600;
}
@font-face {
	font-family: "Vogalis";
	src: url(/public/fonts/Vogalis.ttf);
	font-weight: 400;
}

section {
	scroll-snap-align: start;
	scroll-snap-type: y mandatory;
}

.navbar {
	background: rgba(0, 0, 0, 0.52);
	backdrop-filter: blur(25px);
}
.navbar2 {
	background: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(25px);
}
.navbar3 {
	background: rgba(0, 0, 0, 0.52);
	backdrop-filter: blur(5px);
}
.navbar4 {
	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
}
.hero {
	background-image: url(./assets/radial.svg);
}
.hero2 {
	background-image: url(./assets/radial2.svg);
}
.blurr {
	background-image: url(./assets/blur-1.svg);
}
.radial {
	background: radial-gradient(
		49.2% 49.18% at 49.43% 50.82%,
		#0b212d 0%,
		#040d12 100%
	);
}
.radial2 {
	background: radial-gradient(
		49.2% 49.18% at 49.43% 50.82%,
		#a3c3d4 0%,
		#dcf2ff 100%
	);
}

.loader {
	height: 400px; /* Adjust based on your design */
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

.list-circle li::before {
	position: absolute;
	left: -7px;
	/* margin-top: 4px; */
	content: "";
	display: inline-block;
	width: 1em;
	height: 1em;
	/* border: 2px solid #ff6e4a; */

	border-radius: 50%;
	margin-right: 0.5em;
}

.inner1::before {
	background-color: #dcf2ff;
	border: 2px solid #040d12;
}
.inner2::before {
	background-color: #dcf2ff;
}

@media (max-width: 768px) {
	.inner1::before {
		border-width: 1.5px;
	}
}

@media (max-width: 640px) {
	.inner1::before {
		border-width: 1px;
	}
}

@keyframes moveLeftRight {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(20px); /* Adjust the value to your preference */
	}
	100% {
		transform: translateX(0);
	}
}

.animate-left-right {
	animation: moveLeftRight 2s infinite; /* Adjust the duration to your preference */
}

.underline-thick {
	display: inline-block;
	position: relative;
}

.underline-thick::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px; /* Adjust the thickness here */
	background-color: currentColor; /* Matches the text color */
	transform: scaleX(0);
	transition: transform 0.3s ease;
	transform-origin: right;
}

.underline-thick:hover::after {
	transform: scaleX(1);
	transform-origin: left;
}

.no-scroll {
	overflow: hidden;
	height: 100%;
}

.opinion-container {
	display: flex;
	flex-wrap: nowrap;
	animation: scrollLeftRight 40s linear infinite;
}

.opinion-container > * {
	flex-shrink: 0;
}

.opinion-container:hover {
	animation-play-state: paused;
}

@keyframes scrollLeftRight {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-70%);
	}
	100% {
		transform: translateX(5%);
	}
}

@keyframes scrollLeftInfinite {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

.opinion-container2 {
	flex-wrap: nowrap;
	animation: scrollUp 60s linear infinite;
}

.opinion-container2 > * {
	flex-shrink: 0;
}

.opinion-container2:hover {
	animation-play-state: paused;
}
